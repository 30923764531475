const firebaseConfig = {
	apiKey: 'fapiKey',
	authDomain: 'fauthDomain',
	projectId: 'fprojectId',
	storageBucket: 'fstorageBucket',
	messagingSenderId: 'fmessagingSenderId',
	appId: 'fappId',
	measurementId: 'fmeasurementId',
}

export default firebaseConfig
